<template>
  <div class="row books">
    <div class="col-md-12 mt-2">
      <div class="card">
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row justify-content-md-center">
            <div class="col-sm-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-5">
              <input
                type="text"
                class="form-control"
                placeholder="type name of vendor..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-md-1 col-sm-2">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: '/vendor/create' }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <button class="btn btn-sm btn-dark" @click="actionGetVendor">
              <i class="fa fa-refresh"></i>
            </button>
            <label for="" class="col-sm-auto">Count </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <br />
          <b-table
            sticky-header="500px"
            :items="vendors"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(Action)="row">
              <router-link
                :to="{ path: '/vendor/edit/' + row.item.id }"
                class="link"
              >
                {{ row.item.name.replace(/_/g, ' ') }}
              </router-link>
            </template>
            <template #cell(vendorIDax)="row">
              {{
                row.item.sync_vendor ? row.item.sync_vendor.vendor_id_ax : '-'
              }}
            </template>
            <template #cell(cas_organization)="row">
              {{
                row.item.cas_organization ? row.item.cas_organization.name : '-'
              }}
            </template>
            <template v-slot:cell(is_active)="row">
              <div>
                <span v-if="row.item.is_active" class="badge badge-success">
                  Active
                </span>
                <span v-else class="badge badge-danger"> InActive </span>
              </div>
            </template>
            <template v-slot:cell(is_external)="row">
              <div>
                <span v-if="row.item.is_external">
                  External
                </span>
                <span v-else> Internal </span>
              </div>
            </template>
            <template v-slot:cell(button)="row">
              <div class="btn-group" role="group" aria-label="Basic example">
                <router-link
                  :to="{ path: '/vendor/detail/' + row.item.id }"
                  class="link btn btn-warning"
                >
                  <i class="fa fa-eye"></i>
                </router-link>

                <router-link
                  :to="{ path: '/vendor/edit/' + row.item.id }"
                  class="link btn btn-info"
                >
                  <i class="fa fa-pencil"></i>
                </router-link>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'Vendor',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      sortBy: 'name',
      typing: null,
      sortDesc: false,
      debounce: null,
      message: '',
      fields: [
        { key: 'No', label: 'No' },
        {
          key: 'Action',
          label: 'Name',
          thStyle: { width: '15%' },
        },
        {
          key: 'description',
          label: 'Description',
          thStyle: { width: '23%' },
          formatter: function(value) {
            return value != null ? value.replace(/_/g, ' ') : '';
          },
        },
        {
          key: 'vendorIDax',
          label: 'Sync Vendor ID AX',
          thStyle: { width: '10%' },
        },
        {
          key: 'cas_organization',
          label: 'Organization',
          thStyle: { width: '16%' },
        },
        { key: 'is_external', label: 'Vendor Type' },
        { key: 'is_active', label: 'Status' },
        {
          key: 'created',
          label: 'Created',
          formatter: function(value) {
            return moment(value).format('DD-MM-YYYY');
          },
        },
        {
          key: 'modified',
          label: 'modified',
          formatter: function(value) {
            return moment(value).format('DD-MM-YYYY');
          },
        },
        {
          key: 'button',
          label: '',
        },
      ],
      status: '',
      statusOptions: [
        { value: '', text: 'All' },
        { value: 'true', text: 'Active' },
        { value: 'false', text: 'In Active' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.actionGetVendor();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Vendor | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.vendors.isLoading,
      isError: (state) => state.vendors.isError,
      totalRows: (state) => state.vendors.totalRows,
      vendors: (state) => state.vendors.items,
    }),
  },
  mounted() {
    this.actionGetVendor();
  },
  methods: {
    ...mapActions('vendors', [
      'fetchVendors',
      'searchVendors',
      'fetchVendorsById',
    ]),
    actionGetVendor() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.status) payload.status = this.status;
      if (this.message) payload.name = this.message;
      this.fetchVendors(payload);
    },
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },
    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        let payload = {
          page: 1,
          limit: this.perPage,
        };
        if (this.status) payload.status = this.status;
        if (this.message) payload.name = this.message;
        this.fetchVendors(payload);
      }, 600);
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return 'Type Here';
      }
    },
    actionFindOne(query) {
      this.fetchVendorsById(query);
    },
    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    onStatusChange() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.status) payload.status = this.status;
      if (this.message) payload.name = this.message;
      this.fetchVendors(payload);
    },
  },
};
</script>
